import { useInfiniteQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { AnyVariables, DocumentInput } from 'urql'
import configuration from '~/configuration'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'

export const useInfinityGraphPage = <
  Data = any,
  Variables extends AnyVariables = AnyVariables
>({
  queryDocumentNote,
  getVariable,
  getPageAttribute,
  queryKey,
  enabled = true
}: {
  queryDocumentNote: DocumentInput<Data, Variables>
  getVariable: (page: number) => Partial<Variables>
  getPageAttribute: (
    last: Data,
    group: Data[]
  ) => { totalCount: number; pageLength: number }
  queryKey: string[]
  enabled?: boolean
}) => {
  const { clientGraphQL } = useContextGraphQL()
  const {
    data,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetching,
    isFetchedAfterMount,
    isLoading
  } = useInfiniteQuery<Data, {}, Data, string[]>(
    queryKey,
    useCallback(
      (context) => {
        return (
          clientGraphQL
            .query(queryDocumentNote, getVariable(context.pageParam || 1))
            .toPromise()
            //@ts-expect-error
            .then((rs) => {
              if (rs.error) {
                return catchErrorFromGraphQL({
                  error: rs.error,
                  page: configuration.path.candidates.list
                })
              }
              return rs.data || ({} as Data)
            })
        )
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [getVariable, queryDocumentNote]
    ),
    {
      getNextPageParam: (_lastGroup, groups) => {
        const { totalCount, pageLength } = getPageAttribute(_lastGroup, groups)

        const isHaveNext =
          (!totalCount && totalCount > 0) ||
          totalCount > groups.length * pageLength
        return isHaveNext ? groups.length + 1 : undefined
      },
      refetchOnWindowFocus: false,
      enabled: enabled
    }
  )
  return useMemo(
    () => ({
      data,
      fetchNextPage,
      refetch,
      hasNextPage,
      isFetching,
      isFetchedAfterMount,
      isLoading
    }),
    [
      data,
      fetchNextPage,
      refetch,
      hasNextPage,
      isFetching,
      isFetchedAfterMount,
      isLoading
    ]
  )
}
